<template>
<v-container fluid class='mt-8' id='work'>
  <v-row>
          <v-col align='center'>
            <h1 class='mt-8'>Education</h1>
            <v-container class='mb-4 mt-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-school</v-icon>
              </v-avatar>
              <h2>Msc Business Informatics</h2>
              <h3>Utrecht University | 2021 - Present</h3>
            </v-container>
            <v-container class='mb-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-school</v-icon>
              </v-avatar>
              <h2>Exchange in Business Information Systems</h2>
              <h3>Monash University Melbourne | 2022</h3>
            </v-container>
            <v-container class='mb-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-school</v-icon>
              </v-avatar>
              <h2>Bsc Information Science</h2>
              <h3>Utrecht University | 2018 - 2021</h3>
            </v-container>
          </v-col>
          <v-divider inset vertical class="my-16 primary hidden-xs-only"></v-divider>
          <v-col align='center'>
            <h1 class='mt-8'>Experience</h1>
            <v-container class='mb-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-database</v-icon>
              </v-avatar>
              <h2>Data Solution Consultant</h2>
              <h3>Incentro | 2023 - Present</h3>
            </v-container>
            <v-container class='mb-4 mt-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-human-male-board</v-icon>
              </v-avatar>
              <h2>Teaching Assistant</h2>
              <h3>Utrecht University | 2021 - 2022</h3>
            </v-container>
            <v-container class='mb-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-account</v-icon>
              </v-avatar>
              <h2>Data Engineer</h2>
              <h3>PostNL (via Wiconic) | 2020 - 2022</h3>
            </v-container>
          </v-col>
          <v-divider inset vertical class="my-16 primary hidden-xs-only"></v-divider>
          <v-col align='center'>
            <h1 class='mt-8'>Certifications</h1>
            <v-container class='mb-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-school</v-icon>
              </v-avatar>
              <h2>Azure Data Fundamentals (DP-900)</h2>
              <h3>Microsoft | 2023</h3>
            </v-container>
            <v-container class='mb-4 mt-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-school</v-icon>
              </v-avatar>
              <h2>Certificate of Advanced English</h2>
              <h3>Cambridge Assessment English | 2017</h3>
            </v-container>
          </v-col>
          <v-divider inset vertical class="my-16 primary hidden-xs-only"></v-divider>
          <v-col align='center'>
            <h1 class='mt-8'>Publications</h1>
            <v-container class='mb-4'>
              <v-avatar class='mb-2' color="accent" size="42">
                <v-icon color='white'>mdi-book</v-icon>
              </v-avatar>
              <h2>
                <a href="https://link.springer.com/chapter/10.1007/978-3-031-21388-5_24"
                   style="text-decoration: none">
                    Rapid Delivery of Software: The Effect of Alignment on Time to Market
                </a>
               </h2>
              <h3>Pechlivanidis, K., & Wagenaar, G. | 2022 </h3>
            </v-container>
          </v-col>
  </v-row>
</v-container>
</template>

<script>
  export default {
    name: 'Work'
  }
</script>

<style scoped>


h1 {
  font-weight: 500;
  font-size: 42px;
  color: #1a2238;
}

h2 {
  font-weight: 400;
  font-size: 18px;
  color: #1a2238;
}

h3 {
  font-weight: 350;
  font-size: 16px;
  color: #1a2238;
}

</style>