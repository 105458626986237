<template>
      <v-container fluid class='mt-8 accent' id='skills'>
          <v-row>
            <v-col>
              <v-col align='center'>
                <p class='primary--text text-h3 font-weight-medium'>Skills</p>
                <v-divider class='divider mb-4 primary'></v-divider>
              </v-col>
                <carousel v-bind:per-page-custom=[[480,3],[780,4],[1024,5]] loop='true' autoplay='true' autoplayTimeout='10000' speed='2500' autoplayHoverPause='true' paginationActiveColor='#1a2238'>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-language-python</v-icon></v-avatar>
                      <h2>Python</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-database</v-icon></v-avatar>
                      <h2>ETL</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-git</v-icon></v-avatar>
                      <h2>Git</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-chart-line-variant</v-icon></v-avatar>
                      <h2>CI/CD</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-aws</v-icon></v-avatar>
                      <h2>AWS</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-server-off</v-icon></v-avatar>
                      <h2>Serverless Computing</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-linux</v-icon></v-avatar>
                      <h2>Linux</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-server</v-icon></v-avatar>
                      <h2>Distributed Computing</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-docker</v-icon></v-avatar>
                      <h2>Docker</h2>
                    </v-col>
                  </slide>
                  <slide>
                    <v-col align='center'>
                      <v-avatar class='mb-3' color="primary" size="104"><v-icon size="74" color='white'>mdi-chart-donut</v-icon></v-avatar>
                      <h2>Business Intelligence</h2>
                    </v-col>
                  </slide>
                </carousel>
            </v-col>
          </v-row>
      </v-container>
</template>

<script>
  export default {
    name: 'Skills',
  }
</script>

<style scoped>

h1 {
  font-weight: 500;
  font-size: 42px;
}

h2 {
  font-weight: 500;
  font-size: 18px;
  color: #1a2238;
}

.divider {
       border-width: 1px !important;
       width: 40px;
}

</style>