<template>
      <v-container fluid class='mt-16 primary' id='about'>
          <v-row>
            <v-col class='mb-8 mt-8' offset='1'>
              <p class='white--text text-h3 font-weight-medium'>About me</p>
              <v-divider class='divider mb-6' align='center'></v-divider>
              <v-row>
                <v-col xs='12' lg='6' class='mb-6'>
                  <p class='white--text mb-4 text-h5 font-weight-medium'>I am Kouros, an analytical Data Engineer currently working for Incentro</p>
                  <p class='white--text'>
                    As a Data Engineer, I solve complex organisational problems with a data-driven approach. 
                    In my work, I help organisations build a robust data infrastructure, all while providing a transparent and understandable overview to both technical and non-technical stakeholders. 
                    Besides my passion for the data industry, I am also interested in the field of Web Design and Development.
                  </p>

                  <p class='white--text'>
                    When engaging on a project, I work with a 'Getting-things-done' attitude. 
                    For me, a project is only completed once all involved stakeholders (including me) are satisfied with the result.
                    Besides that, I try to establish an open culture in the projects I am working on, welcoming all innovative ideas to solve a problem.
                  </p>
                  <p class='white--text'>
                    When I am not working on one of my projects, 
                    you will find me listening to some vinyl records, learning to play the guitar,
                    enjoying a night out with friends or going to the gym.
                  </p>
                </v-col>
              </v-row>
                <v-btn class='mr-8 my-4 btn' outlined x-large color="accent" elevation="2" href="KourosPechlivanidisCV.pdf" download><v-icon class='mr-2'>mdi-file-download</v-icon>Download CV</v-btn>
                <v-btn @click="$vuetify.goTo('#contact',{ duration: 1100})" x-large class='mr-4 btn align-right' color="accent" elevation="2"><v-icon class='mr-2'>mdi-android-messages</v-icon>Message me</v-btn>
            </v-col>
          </v-row>
      </v-container>
</template>

<script>
  export default {
    name: 'About'
  }
</script>

<style scoped>

h1 {
  font-weight: 500;
  font-size: 42px;
  color: white;
}

h2 {
  color: white;
}

.divider {
       border-width: 1px !important;
       border-color: white !important;
       width: 160px;
}

.btn {
    text-transform: unset !important;
}


</style>