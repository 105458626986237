import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueCarousel from 'vue-carousel';
import "@/assets/global.css";

Vue.use(Vuetify);
Vue.use(VueCarousel);

export default new Vuetify({
    theme: {
      themes: {
        light: {
            primary: '#1a2238',
            secondary: '#A4ACB7',
            accent: '#ff6a3d',
        },
      },
    },
  })
  