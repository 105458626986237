<template>
  <v-container>
    <v-app-bar hide-on-scroll app flat color="primary" elevation='0'>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon color='white' @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
      
      <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
    >

    <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
        >
          <v-list-item @click="$vuetify.goTo('#about',{ duration: 1100})">
            <v-list-item-title class='primary--text'>About me</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$vuetify.goTo('#work',{ duration: 1100})"> 
            <v-list-item-title class='primary--text'>Education & Work Experience</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$vuetify.goTo('#skills',{ duration: 1100})">
            <v-list-item-title class='primary--text'>Skills</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$vuetify.goTo('#testimonials',{ duration: 1100})">
            <v-list-item-title class='primary--text'>Testimonials</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$vuetify.goTo('#contact',{ duration: 1100})">
            <v-list-item-title class='primary--text'>Contact me</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>


<script>
  export default {
    name: 'Header',
    data: () => ({
      drawer: false,
      group: null,
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>

<style scoped>
</style>
