<template>
<v-footer fluid color="primary" class='ma-0 pa-0'>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
  <path fill="white" fill-opacity="1" d="M0,96L60,80C120,64,240,32,360,53.3C480,75,600,149,720,154.7C840,160,960,96,1080,74.7C1200,53,1320,75,1380,85.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
</svg>
<p class='white--text ml-6'>© {{ new Date().getFullYear() }} — Kouros Pechlivanidis. All Rights Reserved.</p>
<v-spacer></v-spacer>
<p class='mr-2'><a class='links' href='https://www.linkedin.com/in/kouros-pechlivanidis-00686b199/'>LinkedIn</a></p>
<p class='white--text mr-2'>|</p>
<p class='mr-6'><a class='links' href='https://github.com/KourosNL'>GitHub</a></p>
</v-footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>

<style scoped>

p {
  font-size: 18px;
}

.links {
text-decoration: none;
border-bottom: 1px solid #fff;
padding-bottom: 1px;
color: #fff;
} 


a:hover
{
    color:#ff6a3d;
    border-color: #ff6a3d;
}



</style>