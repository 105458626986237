<template>
      <v-row class='mt-8 mt-sm-16'>
        <v-col offset="1">
            <v-container>
              <p class='primary--text text-h3 text-lg-h1 font-weight-bold'>Kouros Pechlivanidis</p>
              <p class='mb-2 mb-sm-16 font-italic text-subtitle-1 text-lg-h5 grey--text font-weight-medium'>Msc Student Business Informatics 
                  <span class='accent--text'>•</span> 
                  Data Engineer 
              </p>
                <v-btn href='https://www.linkedin.com/in/kouros-pechlivanidis-00686b199/' x-large class='mb-2 btn mr-2' color="accent" elevation="2">
                  <v-icon x-large class='mr-2'>mdi-linkedin</v-icon>Linkedin
                </v-btn>
                <v-btn href='https://github.com/KourosNL' x-large class='mb-2 btn' color="accent" elevation="2">
                  <v-icon x-large class='mr-2'>mdi-github</v-icon>GitHub
                </v-btn>
              <div class='accent--text text-subtitle-1 text-lg-h5 font-weight-medium mt-8'>
                <v-icon class='mr-2' color='primary' x-large>mdi-email-outline</v-icon>Email</div>
              <div class='primary--text text-subtitle-1 text-lg-h5 font-weight-medium'>
                <a href="mailto:kouros.pechlivanidis@gmail.com">kouros.pechlivanidis@gmail.com</a> (personal)<br>
                <a href="mailto:kouros.pechlivanidis@incentro.com">kouros.pechlivanidis@incentro.com</a> (work)
              </div>
            </v-container>
        </v-col>
        <v-col align='center' class="mt-n16">
          <v-container>
            <v-img v-if="$vuetify.breakpoint.xs" class='profile mt-16' width='70vw' src='../assets/profile.jpg'></v-img>
            <v-img v-if="$vuetify.breakpoint.smAndUp" class='profile mt-16' width='35vw' src='../assets/profile.jpg'></v-img>
          </v-container>
        </v-col>
      </v-row>
</template>


<script>
  export default {
    name: 'Intro'
  }
</script>

<style scoped>

.btn {
    text-transform: unset !important;
}

.profile{
  border-radius: 10%;
}




</style>