<template>
  <v-app>
    <Header/>
    <v-main>
      <Intro/>
      <About/>
      <Work/>
      <Skills/>
      <Testimonials/>
      <Contact/>
      <Footer/>
    </v-main>
  </v-app>

</template>

<script>
import Header from './components/Header';
import Intro from './components/Intro';
import About from './components/About';
import Work from './components/Work.vue';
import Skills from './components/Skills.vue';
import Testimonials from './components/Testimonials.vue';
import Footer from './components/Footer.vue';
import Contact from './components/Contact.vue';

export default {
  name: 'App',

  components: {
    Header,
    Intro,
    About,
    Work,
    Skills,
    Testimonials,
    Contact,
    Footer
  },

  data: () => {
  },
};
</script>

<style scoped>


</style>
