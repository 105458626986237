<template>
      <v-row class='mt-8' id='contact'>
        <v-col offset='2' class="mt-16" cols='12' md='4'>
        <p class='primary--text text-h3 font-weight-medium'>Contact me</p>
          <p class='contact-info primary--text'>I am open to discuss projects in the field of <strong>Data Engineering</strong>. Send me a message to discuss your next project!
          </p>
        </v-col>
        <v-col offset='2' class="mt-16 text-right offset-md-0" align='center' cols='9' md='4'>
          <template v-if='show_message'>
            <v-alert transition="slide-y-transition" type="success">Thank you for your message! I will get back to you as soon as possible!</v-alert>
          </template>
          <template>
            <form id='form' @submit.prevent="sendEmail">
              <v-text-field
               color='primary'
                outlined
                v-model="name"
                :error-messages="nameErrors"
                label="Your Name"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                        ></v-text-field>
              <v-text-field
                color='primary'
                outlined
                v-model="email"
                :error-messages="emailErrors"
                label="Your E-mail"
                required
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              ></v-text-field>

              <v-textarea
                color='primary'
                label="Your message"
                outlined
                rows="5"
                no-resize
                row-height="25"
                v-model="message"
                :error-messages="nameErrors"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              ></v-textarea>
              <v-btn type="submit" class="mr-4 accent btn" large @click="submit">Submit</v-btn>
            </form>
          </template>
        </v-col>
        <v-col offset='1' md='0'>
        </v-col>
      </v-row>
</template>


<script>
import emailjs from 'emailjs-com';
  export default {
    name: 'Contact',
    data() {
    return {
      name: '',
      email: '',
      message: '',
      show_message: false,
    }
  },
  methods: {
    sendEmail() {
      try {
        emailjs.send('service_u033bnr', 'template_1bux734', {
          name: this.name,
          email: this.email,
          message: this.message
        },
        'user_xXXBzmoL542IYQrIUi5rx')

        this.show_message = true;
        window.setInterval(() => {
        this.show_message = false;
      }, 8000)  

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
  }
</script>

<style scoped>


h1 {
  font-weight: 500;
  font-size: 42px;
}

.contact-info {
  width:75%;
}

.btn {
    text-transform: unset !important;
}

</style>