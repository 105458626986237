<template>
      <v-container fluid class='mt-8' id='testimonials'>
          <v-row>
            <v-col align='center'>
            <p class='primary--text text-h3 font-weight-medium'>Testimonials</p>
              <v-divider class='divider primary mb-16'></v-divider> 
                  
                  <v-card v-if="$vuetify.breakpoint.mdAndDown" width='80%' shaped class='primary rounded-lg mb-8 text-left'>
                    <v-row>
                      <v-col>
                        <v-card-title class='white--text'>Jan Vlietland</v-card-title>
                        <v-card-subtitle class='white--text'>Managing Director at Wiconic</v-card-subtitle>
                        <v-card-text class='white--text card-text font-italic'>
                          "Over the past two years, Kouros has been one of my employees at Wiconic. 
                          I see Kouros as a dedicated, loyal and eager professional. His enthousiasm is contagious. I enjoy working with Kouros (and hope that we will do so for a very long time). 
                          Through his academic and practical skills he has and will have a lasting impact on his environment."
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>

                 <v-card v-if="$vuetify.breakpoint.mdAndDown" width='80%' shaped class='primary rounded-lg mb-8 text-left'>
                    <v-row>
                      <v-col>
                        <v-card-title class='white--text'>Tim Smit</v-card-title>
                        <v-card-subtitle class='white--text'>Msc Business Informatics at Utrecht University</v-card-subtitle>
                        <v-card-text class='white--text card-text font-italic'>
                          "Kouros is that guy who may come across confident, not because he knows everything but because he is not afraid to learn something new. 
                          In the many (academic) projects that I've worked with him, Kouros has shown to be a real teamplayer, who responds realisticly and with an open mind to others' ideas. 
                          I've found him to be trustworthy, adept, and just plain hard-working."
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card v-if="$vuetify.breakpoint.lgAndUp" width='50%' shaped class='primary rounded-lg mb-8 text-left'>
                    <v-row  align="center" justify="center">
                      <v-col cols='3' class='pl-8'>
                        <a href='https://www.linkedin.com/in/vlietland/'><v-img class='profile' contain width='20vw' src='../assets/janvlietland.jpg'></v-img></a>
                      </v-col>
                      <v-col cols='9'>
                        <v-card-title class='white--text'>Jan Vlietland | <span class='role ml-2'>Managing Director at Wiconic</span></v-card-title>
                        <v-card-text class='white--text card-text font-italic'>
                          "Over the past two years, Kouros has been one of my employees at Wiconic. 
                          I see Kouros as a dedicated, loyal and eager professional. His enthousiasm is contagious. I enjoy working with Kouros (and hope that we will do so for a very long time). 
                          Through his academic and practical skills he has and will have a lasting impact on his environment."
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                 <v-card v-if="$vuetify.breakpoint.lgAndUp" width='50%' shaped class='primary rounded-lg mb-8 text-left'>
                     <v-row  align="center" justify="center">
                      <v-col cols='9'>
                        <v-card-title class='white--text'>Tim Smit | <span class='role ml-2'>Msc Business Informatics at Utrecht University</span></v-card-title>
                        <v-card-text class='white--text card-text font-italic'>
                          "Kouros is that guy who may come across confident, not because he knows everything but because he is not afraid to learn something new. 
                          In the many (academic) projects that I've worked with him, Kouros has shown to be a real teamplayer, who responds realisticly and with an open mind to others' ideas. 
                          I've found him to be trustworthy, adept, and just plain hard-working."
                        </v-card-text>
                      </v-col>
                      <v-col cols='3' class='pr-8'>
                        <a href='https://www.linkedin.com/in/profieltimsmit/'><v-img class='profile' contain width='20vw' src='../assets/timsmit.jpg'></v-img></a>
                      </v-col>
                    </v-row>
                  </v-card>
            </v-col>
          </v-row>
      </v-container>
</template>

<script>
  export default {
    name: 'Testimonials'
  }
</script>

<style scoped>

h1 {
  font-weight: 500;
  font-size: 42px;
}

.divider {
       border-width: 1px !important;
       width: 200px;
}

.profile{
  border-radius: 10%;
}

.role {
  font-weight: 500;
  font-size: 15px;
  vertical-align: middle;
}

/* .card-text {
  font-size: px;
} */



</style>